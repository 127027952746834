<template>
    <div v-if="dataSource"
        :key="document.id"
        class="p-relative"
    >

        <Toolbar :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick"  />
        <DocumentPanel :document-info="this.dataSource.Data.Object" />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            mobile-breakpoint="0"
            class="tab-selector-panel"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="document.id + item.FormId"
                @click="onTabClicked(item.FormId)"                
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{item.Count}}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="document.id + item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card-panel  v-if="isRegistered" title="Сведения_о_регистрации">

                                    <v-field label="Регистрационный_номер">
                                        <template v-slot:view>{{ document.Card.RegNumber }}</template>
                                    </v-field>
                                    <v-field label="Дата_регистрации">
                                        <template v-slot:view>{{ formatDate(document.Card.RegDate) }}</template>
                                    </v-field>
                                    <v-field label="Регистратор">
                                        <template v-slot:view>
                                            <v-employee-chip v-if="document.Card.RegistratorName" :id="document.Card.RegistratorEmployeeId" :name="document.Card.RegistratorName" />
                                        </template>
                                    </v-field>                                   

                                </v-card-panel>

                                <v-card-panel title="Служебная_информация">

                                    <v-field label="Исх._номер_заявления,_дата_создания">
                                        <template v-if="locale=='ru'" v-slot:view>{{ document.Card.UniqueNumber }}{{ !!document.Card.PortalRegDate ? ` ${$t('от_время')} ${document.Card.PortalRegDate}г.` : '' }}</template>
                                        <template v-else v-slot:view>{{ document.Card.UniqueNumber }}{{ !!document.Card.PortalRegDate ? ` ${document.Card.PortalRegDate} ${$t('от_время')}` : '' }}</template>
                                    </v-field>
                                    <v-field label="Поступило_из">
                                        <template v-slot:view>
                                            <div v-if="isPortalStatement" class="onlyReadData">{{$t("Портал")}}</div>
                                            <div v-else-if="isProactive" class="onlyReadData">{{$t("Проактив")}}</div>
                                            <div v-else class="onlyReadData">{{$t("Фронт-офис")}}</div>
                                        </template>
                                    </v-field>
                                    <v-field label="Код_услуги">
                                        <template v-slot:view>{{ document.Card.ServiceCode }}</template>
                                    </v-field>
                                    <v-field label="Услуга">
                                        <template v-slot:view>{{locale == 'ru' ? document.Card.Annotation["ru-RU"] : document.Card.Annotation["kk-KZ"]}}</template>
                                    </v-field>                                    

                                </v-card-panel>

                            </v-col>
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                
                                <DocumentStatusCard 
                                    v-if="!document.is_new_record && isRegistered" 
                                    :controlInfo="controlInfo"
                                />

                                <v-card-panel v-if="document.Card.ExecDate" title="Исполнение">
                                    <v-field v-if="document.Card.ExecutionStatus" label="Решение">
                                        <template v-slot:view>{{ $t(decisions[document.Card.ExecutionStatus])}}</template>
                                    </v-field>
                                    <v-field label="Дата_исполнения">
                                        <template v-slot:view>{{ formatDateTime(document.Card.ExecDate) }}</template>
                                    </v-field>
                                    <v-field label="Исполнитель">
                                        <template v-slot:view>
                                            <v-workplace-chip v-if="document.Card.ExecuterName" :id="document.Card.ExecuterWorkplaceId" :name="document.Card.ExecuterName" />
                                        </template>
                                    </v-field>
                                </v-card-panel>

                                <v-card-panel title="Заявитель">

                                    <v-field label="Категория_лица">
                                        <template v-slot:view>{{ $t(declarantCategoryName) }}</template>
                                    </v-field>

                                    <template v-if="[3, 4, 5, 6].includes(document.Card.DeclarantType)">                                        
                                        <v-field label="БИН_организации">
                                            <template v-slot:view>{{ document.Card.DeclarantBIN }}</template>
                                        </v-field>
                                        <v-field label="Наименование_организации">
                                            <template v-slot:view>{{ document.Card.DeclarantEnterpriseName }}</template>
                                        </v-field>
                                        <v-field label="Юридический_адрес">
                                            <template v-slot:view>{{ document.Card.DeclarantEnterpriseAddress }}</template>
                                        </v-field>                                        
                                    </template>

                                    <v-field v-if="[1,2].includes(document.Card.DeclarantType)" label="ИИН">
                                        <template v-slot:view>{{ document.Card.DeclarantIIN }}</template>
                                    </v-field>
                                    <v-field label="ФИО">
                                        <template v-slot:view>{{ document.Card.DeclarantFirstName }} {{ document.Card.DeclarantSecondName }} {{ document.Card.DeclarantMiddleName }}</template>
                                    </v-field>
                                    <v-field label="Документ,_удостоверяющий_личность">
                                        <template v-slot:view>{{ locale == 'ru' ? document.Card.DeclarantDocumentType["ru-RU"] : document.Card.DeclarantDocumentType["kk-KZ"] }}</template>
                                    </v-field>
                                    <v-field label="Номер_документа,_когда_и_кем_выдан">
                                        <template v-slot:view>{{ document.Card.DeclarantDocumentNumber }}, {{document.Card.DeclarantDocumentDate}}, {{document.Card.DeclarantDocumentIssued}} </template>
                                    </v-field>   
                                    <v-field label="Адрес">
                                        <template v-slot:view>{{ document.Card.DeclarantAddress }}</template>
                                    </v-field>    
                                    <v-field label="Телефон">
                                        <template v-slot:view>{{ document.Card.DeclarantPhone }}</template>
                                    </v-field>    
                                    <v-field label="Email">
                                        <template v-slot:view>{{ document.Card.DeclarantEmail ? document.Card.DeclarantEmail : $t('Не_указано')  }}</template>
                                    </v-field>   
                                    <v-field v-if="document.Card.AuthorityDoc" label="Документ_подтверждающий_полномочия">
                                        <template v-slot:view>{{ document.Card.AuthorityDoc }}, {{ document.Card.AuthorityDocDate }}</template>
                                    </v-field>   
                                </v-card-panel>

                                <v-card-panel v-if="!!document.Card.BIC" title="Банковские_реквизиты">
                                    <v-field label="ИИК">
                                        <template v-slot:view>{{ document.Card.IIC }}</template>
                                    </v-field>
                                    <v-field label="БИК_(SWIFT)">
                                        <template v-slot:view>{{ document.Card.BIC }}</template>
                                    </v-field>

                                    <v-field label="Кбе">
                                        <template v-slot:view>{{ document.Card.BeneficiaryCode }}</template>
                                    </v-field>
                                </v-card-panel>


                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                                <!--Секция На рассмотрение-->
                                <v-card v-if="isViewMode && document.Card.Recipients && document.Card.Recipients.length > 0" flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("На_рассмотрение") }}</div>
                                            <v-row class="full-width-row" no-gutters>
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{ $t("Рассматривающие") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">
                                                    <div class="onlyReadData more-per-lab-wrap">
                                                        <template v-for="recipient in document.Card.Recipients">
                                                            <v-workplace-chip
                                                                :key="recipient.Id"
                                                                :id="recipient.Id"
                                                                :name="recipient.Name"
                                                                :is-employee="recipient.IsEmployee"
                                                            />
                                                        </template>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-form>
                </template>

                <template v-else>
                    <component                        
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId" 
                        :id="document.id"                 
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>
    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n'
import sys from '@/services/system';
import DocumentStatusCard from '@/components/widgets/DocumentStatus';

export default
{
    name: "IQalaCard",
    props: {
        id: {
            type: String,
            default: null
        }
    },
    components: {             
        Toolbar,
        DocumentPanel,
        DocumentStatusCard,
    },
    computed: {
        ...mapGetters('actionsource', { controlInfo: 'getControlInfo', dataSource: 'getDataSource' }),
        ...mapGetters('references', ['GetIQalaDeclarantCategoryTypes']),
        isViewMode() {
            return this.dataSource.Data.FormId == "0201081";
        },
        document() {
            return this.dataSource?.Data?.Object?.Document;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: i18n.t("Карточка") }
            ];
           
            pages = pages.concat(this.dataSource?.Data?.Object?.Pages?.filter( page => page.FormId != '0201081') ?? []);

            return pages;
        },
        isRegistered() {
            return this.document.RegState == 1 || this.document.RegState == 4; 
        },
        locale() {
            return i18n.locale;
        },
        isPortalStatement() {
            return this.document.Card.UniqueNumber.startsWith('ПО.');
        },
        isProactive(){
            return this.document.Card.UniqueNumber.startsWith('АВТ.');
        },
        declarantCategoryName() {
            return this.GetIQalaDeclarantCategoryTypes.find(category => category.id === this.document.Card.DeclarantType).Value;
        },         
    },
    data() {
        return {
            decisions: ['', 'Положительный_ответ', 'Мотивированный_отказ'],
            cancellationTokenSorce: null,
            tab: null,
            userSettings: {},
            currentFormId: null,
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async onTabClicked (formId) {
            this.currentFormId = formId;
            if (this.$refs[formId])
                await this.$refs[formId][0]?.update();
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})

            if (actions.includes(button.Action)) {
                await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        formatDate(d)
        {
            return sys.dateFormat(d, 'DD.MM.YYYY');
        }, 
        formatDateTime(d)
        {
            return sys.dateFormat(d, 'DD.MM.YYYY HH:mm');
        }
    },
    mounted() {        
        this.$eventBus.$on('set-active-tab', async ({ FormId }) => {
            let page = this.pages.find(x => x.FormId === FormId);

            if (page) {
                let targetIndex = this.pages.indexOf(page);

                if (this.tab != targetIndex)
                    this.tab = targetIndex;
                else
                    this.$eventBus.$emit('update-active-tab');
            }
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('set-active-tab');
    }
}
</script>